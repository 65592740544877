import { apiSlice } from 'redux/api/auth/apiSlice'

export const flatsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getFlats: builder.query<any, any>({
      query: ({ query, residential_complex }: any) => {
        return {
          url: 'api/flats/',
          params: {
            query,
            residential_complex,
          },
        }
      },
    }),
    getFlatsBybuilding: builder.query<any, any>({
      query: ({ query, building }: any) => {
        if (!building) return
        return {
          url: 'api/flats/',
          params: {
            query,
            building,
          },
        }
      },
    }),
    getFlatById: builder.query<any, any>({
      query: ({ id }: any) => {
        if (!id) return
        console.log('builo', id)
        return {
          url: `api/flats/${id}`,
        }
      },
    }),

    createFlat: builder.mutation({
      query: ({ id, building, number, floor, account_number, area }: any) => ({
        url: 'api/flats/',
        method: 'POST',
        body: {
          id,
          building,
          number,
          floor,
          account_number,
          area,
        },
      }),
    }),
    updFlat: builder.mutation({
      query: ({ id, number, floor, account_number, area, building }: any) => ({
        url: `api/flats/${id}/`,
        method: 'PUT',
        body: {
          number,
          floor,
          account_number,
          area,
          building,
        },
      }),
    }),
    updFlatMeters: builder.mutation({
      query: ({ flat_pk, id, value }: any) => ({
        url: `api/flats/${flat_pk}/meters/${id}/`,
        method: 'PUT',
        body: {
          value,
        },
      }),
    }),
    addTenantInFlat: builder.mutation({
      query: ({ tenant_id, flat_id }: any) => ({
        url: `api/flats/${flat_id}/add_tenant/`,
        method: 'PATCH',
        body: {
          tenant: tenant_id,
        },
      }),
    }),
    addGuest: builder.mutation({
      query: ({ tenant_id, flat_id }: any) => ({
        url: `api/flats/${flat_id}/add_tenant/`,
        method: 'PATCH',
        body: {
          tenant: tenant_id,
        },
      }),
    }),
    removeFlat: builder.mutation({
      query: (id: any) => ({
        url: `api/flats/${id}/`,
        method: 'DELETE',
      }),
    }),
    removeTenantInFlat: builder.mutation({
      query: ({ tenant_id, flat_id }: any) => ({
        url: `api/flats/${flat_id}/remove_tenant/`,
        method: 'PATCH',
        body: {
          tenant: tenant_id,
        },
      }),
    }),
  }),
})

export const {
  useGetFlatsQuery,
  useGetFlatsBybuildingQuery,
  useLazyGetFlatsBybuildingQuery,
  useCreateFlatMutation,
  useGetFlatByIdQuery,
  useAddTenantInFlatMutation,
  useUpdFlatMutation,
  useUpdFlatMetersMutation,
  useRemoveTenantInFlatMutation,
  useRemoveFlatMutation,
  useAddGuestMutation,
} = flatsApiSlice
