import React, { useEffect, useState } from 'react'
import { Button, Input, Title, FileUploadOrder, FileUpload } from 'components'
import styles from './CreateOrderModal.module.css'
import Modal from '@mui/material/Modal'
import {
  Autocomplete,
  Box,
  createFilterOptions,
  FormControl,
  InputLabel,
  TextField,
} from '@mui/material'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import { SelectGhost } from 'components/Input/SelectGhost/SelectGhost'
import { ReactComponent as CrossSvg } from 'assets/img/cross.svg'
import { ColorDot } from 'components/ColorDot/ColorDot.styles'
import {
  useCreateAppealMutation,
  useEditAppealMutation,
  useGetAppealIssueTypeQuery,
} from 'redux/features/appeals/appealsApiSlice'
import { useSelector } from 'react-redux'
import { useGetComplexesQuery } from 'redux/features/complexes/complexesApiSlice'
import {
  useGetBuildingsQuery,
  useLazyGetBuildingsQuery,
} from 'redux/features/buildings/buildingsApiSlice'
import { useGetTenantsQuery } from 'redux/features/tenats/tenantsApiSlice'
import {
  useGetFlatByIdQuery,
  useGetFlatsBybuildingQuery,
  useLazyGetFlatsBybuildingQuery,
} from 'redux/features/flats/flatApiSlice'
import {
  AddTenantOption,
  Priority,
  PriorityColor,
  PriorityName,
  Status,
  StatusColor,
  StatusName,
  TypeName,
} from './constants'
import { IProps, Option } from './types'
import { useAddAttachmentMutation } from 'redux/features/attachments/attachmentsApiSlice'
import { Calendar } from 'components/Input/Calendar/Calendar'
import { useGetProfileQuery } from 'redux/features/profile/profileApiSlice'
import moment from 'moment'

// export const ColorDot = styled('div')(({ theme }) => ({
//   width: theme.spacing(1.5),
//   height: theme.spacing(1.5),
//   borderRadius: '50%',
//   marginRight: theme.spacing(1),
// }))

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 966,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  padding: '0',
  borderRadius: '8px',
  border: 'none',
}

const defaultFormState = {
  building_id: '',
  flat_id: '',
  address: '',
  description: '',
  client_fullname: '',
  phone: '',
  images: [],
  deadline: '',
  appealIssueType: '',
  appealIssueType_id: '',
  typeVal: '',
}
const StatusOption = ({ status }: any) => {
  return (
    <div className={styles.option_status}>
      <ColorDot style={{ backgroundColor: StatusColor[status] }} />
      <span>{StatusName[status]}</span>
    </div>
  )
}

const PriorityOption = ({ priority }: any) => {
  // useEffect(() => {
  //   console.log('Object.keys(StatusName): + ', Object.keys(StatusName))
  // }, [])
  return (
    <div className={styles.option_status}>
      <ColorDot style={{ backgroundColor: PriorityColor[priority] }} />
      <span>{PriorityName[priority]}</span>
    </div>
  )
}

const optionsStatus: Option[] = Object.keys(StatusName).map((status) => ({
  id: status,
  value: status,
  title: <StatusOption status={status} key={status} />,
}))

const optionsUrgency: Option[] = Object.keys(PriorityName).map((priority) => ({
  id: priority,
  value: priority,
  title: <PriorityOption priority={priority} key={priority} />,
}))

interface CustomFile extends File {
  preview?: string // Assuming 'preview' is a string representing the preview URL
  idFile?: string // Assuming 'preview' is a string representing the preview URL
}

export const CreateOrderModal = ({
  btnTitle,
  editData,
  isDisabled = false,
  refetchAppeals,
  withAddressFields = false,
}: IProps) => {
  const [formState, setFormState] = React.useState<any>({
    test: 'Hey',
    flat_number: null,
    complex: null,
    building_id: '',
    flat_id: '',
    address: '',
    description: null,
    client_fullname: '',
    phone: '',
    status: null,
    priority: null,
    optionsType: null,
    title: '',
    images: [],
    deadline: '',
    appealIssueType: '',
    appealIssueType_id: '',
    typeVal: '',
  })

  const { data: appealIssueType, isLoading: isAppealLoading } = useGetAppealIssueTypeQuery({})
  const [createAppeal] = useCreateAppealMutation()
  const [editAppeal] = useEditAppealMutation()
  const [open, setOpen] = React.useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => {
    setOpen(false)
    setFormState({})
  }
  const [error, setError] = useState('')
  const [attachmentUpload, { isLoading, isSuccess, isError, error: errorFileUpload }] =
    useAddAttachmentMutation()

  const urlToObject = async (imageUrl: string) => {
    const response = await fetch(imageUrl)
    const blob = await response.blob()
    const file: CustomFile = new File([blob], 'image.jpg', {
      type: blob.type,
    })
    file.preview = imageUrl
    file.idFile = imageUrl
    return file
  }

  const imageArrTofiles = async (arr: any[]) => {
    const promises = arr.map(({ image }: any) => {
      return urlToObject(
        // 'https://devfsm.darsit.ru/media/api-service/images/41Mm9Vdg14L._AC_SL1000__8MgkNxo.jpg',
        image,
      )
    })

    const filesLocal = await Promise.all(promises)
    setFiles(filesLocal)

    return files
  }

  React.useEffect(() => {
    if (formState?.images?.length > 0) {
      imageArrTofiles(formState?.images).then((data) => {
        console.log('--', data)
      })
    }
  }, [formState?.images])

  const [errorList, setErrorList] = useState<any>([])
  const { data: tenantsData } = useGetTenantsQuery('')
  const [addTenantOption, setAddTenantOption] = useState(AddTenantOption.FROM_DB)

  const chatRefFlatData = useSelector((state: any) => state?.chat?.chatRefFlatData)

  const [getBuildingsDataQuery, { data: buildingsData, isLoading: isBuildingLoading }] =
    useLazyGetBuildingsQuery()

  const [getFlatsByBuildingData, { data: flatsByBuildingData, isLoading: isFlatsLoading }] =
    useLazyGetFlatsBybuildingQuery()

  useEffect(() => {
    formState.building_id &&
      getFlatsByBuildingData({
        building: formState.building_id,
        query: '',
      })
  }, [formState.building_id])

  const { data: profileData } = useGetProfileQuery()

  const handleCreateAppeal = () => {
    setError('')
    // Create a new File object with the file data
    // const newFileу = new File([files[0]], 'files[0].name', {
    //   type: files[0].type,
    //   lastModified: files[0].lastModified,
    // })

    const appealObj = {
      responsible: profileData?.id,
      addTenantOption,
      appealIssueType: formState?.appealIssueType,
      appealIssueType_id: formState?.appealIssueType_id,
      typeVal: formState?.typeVal,
      deadline: formState?.deadline,
      title: formState?.title,
      description: formState?.description,
      priority: formState?.priority == null ? Priority.LOW : formState?.priority,
      status: formState?.status == null ? Status.NEW : formState.status,
      uploaded_files: files.filter((file: File) => file?.type != 'image/jpeg'),
      uploaded_images: files.filter((file: File) => file?.type == 'image/jpeg'),
      target: {
        type: 1,
        residential_complex: formState.complex,
        building: withAddressFields ? formState.building_id : chatRefFlatData?.building?.id,
        flat: formState?.flat_id || Number(chatRefFlatData?.id),
      },
      initiated_by: {},
    }

    if (addTenantOption === AddTenantOption.FROM_DB) {
      appealObj.initiated_by = {
        tenant: formState.tenant_id,
      }
    }

    if (addTenantOption === AddTenantOption.ADD_NEW_USER) {
      appealObj.initiated_by = {
        phone: formState.phone?.trim(),
        fullname: formState.client_fullname,
      }
    }

    createAppeal({
      ...appealObj,
    })
      .then((data: any) => {
        setFormState({ ...formState, ...defaultFormState })
        if (data?.error) {
          setError(Object.keys(data?.error?.data?.initiated_by)[0])
          setErrorList(Object.keys(data?.error?.data?.initiated_by))
        } else {
          if (refetchAppeals) {
            setError('')
            refetchAppeals().then(() => setOpen(false))
          } else {
            setError('')
            setOpen(false)
          }
        }
      })
      .then(() => setFormState({}))
  }

  // не взял isLoading из мутации так как нужно больше времени на refetchAppeals и loader исчезает
  const [isLoadingEdit, setIsLoadingEdit] = useState(false)

  const handleEditAppeal = () => {
    setError('')
    setIsLoadingEdit(true)

    const appealObj = {
      responsible: profileData?.id,
      appealIssueType: formState.appealIssueType,
      appealIssueType_id: formState.appealIssueType_id,
      typeVal: formState.typeVal,
      deadline: moment(formState.deadline).format('YYYY-MM-DD'),
      close_date: editData.close_date,
      issue: editData.issue,
      target: { type: 1 },
      addTenantOption,
      id: editData?.id,
      description: formState.description,
      priority: formState?.priority,
      status: formState?.status,
      title: formState.title,
      phone: formState.phone?.trim(),
      fullname: formState.client_fullname,
      tenant: editData?.initiated_by?.tenant,
      residential_complex: editData?.target?.residential_complex,
      building: editData?.target?.building,
      flat: editData?.target?.flat,
      uploaded_files: files.filter((file: File) => file?.type != 'image/jpeg'),
      uploaded_images: files.filter((file: File) => file?.type == 'image/jpeg'),
    }

    if (addTenantOption === AddTenantOption.FROM_DB) {
      appealObj.tenant = formState?.tenant_id
    }
    if (addTenantOption === AddTenantOption.ADD_NEW_USER) {
      appealObj.tenant = formState?.editData?.initiated_by?.tenant
    }

    editAppeal(appealObj)
      .then((data: any) => {
        if (data?.error) {
          setError(Object.keys(data?.error?.data)[0])
          setErrorList(Object.keys(data?.error?.data))
          setIsLoadingEdit(false)
        } else {
          setFormState(defaultFormState)
          if (refetchAppeals) {
            refetchAppeals().then(() => {
              setError('')
              setOpen(false)
              setIsLoadingEdit(false)
            })
          } else {
            setError('')
            setOpen(false)
            setIsLoadingEdit(false)
          }
        }
      })
      .catch(() => console.log('ERRRRROR'))
  }

  const onChangeFormHandler = (e: any) => {
    console.log('DATE__', e.target.value)
    setFormState((prev: any) => ({ ...prev, [e.target.name]: e.target.value }))
  }

  const { data: complexesData } = useGetComplexesQuery({})

  const fillFields = () => {
    if (editData && appealIssueType) {
      setAddTenantOption(AddTenantOption.ADD_NEW_USER)
      setFormState({
        ...formState,
        // flat_id: Number(editData?.flat_id),
        flat_id: editData?.target?.flat,
        title: editData?.target?.address,
        description: editData?.description,
        status: editData?.status,
        priority: editData?.priority,
        type: editData.type,
        typeVal: editData.type,
        deadline: moment(editData.deadline).format('YYYY-MM-DD'),
        close_date: editData.close_date,
        issue: editData.issue,
        phone: editData.initiated_by.phone,
        client_fullname: editData.initiated_by.fullname,
        tenant: editData.initiated_by.tenant,
        residential_complex: editData.residential_complex,
        building: editData.building,
        flat: editData.flat,
        images: editData.images,
        appealIssueType_id: editData?.issue_type?.id,
        appealIssueType:
          appealIssueType &&
          appealIssueType?.filter(({ id }: any) => editData?.issue_type?.id == id)[0]?.title,
        //   appealIssueType?.filter(({ id }: any) => editData.issue_type.id == id)[0], // editData.appealIssueType,
      })
    } else {
      setFormState({
        ...formState,
        title: `Здание. ${chatRefFlatData?.chatsDataFSM?.building?.building} кв ${chatRefFlatData?.chatsDataFSM?.number}`,
        building: chatRefFlatData?.building?.id,
        complex: chatRefFlatData?.building?.residential_complex?.id,
        flat_id: Number(chatRefFlatData?.chatsDataFSM?.flat),
      })
    }
  }

  useEffect(() => {
    fillFields()
  }, [editData, complexesData, chatRefFlatData, appealIssueType])

  useEffect(() => {
    if (formState.complex) {
      getBuildingsDataQuery({
        qury: '',
        residential_complex: formState?.complex,
      })
    }
  }, [formState.complex])

  // AFTER SELECTED
  const onAutocompleteHandler = (e: any, value: any) => {
    const { id, first_name, last_name, middle_name, phone } = value
    setFormState({
      ...formState,
      tenant_id: id,
      phone,
      first_name,
      last_name,
      middle_name,
      client_fullname: first_name + ' ' + last_name + ' ' + middle_name,
    })
  }

  // ON CHANGE
  const onAutocompleteInputChange = (e: any, value: any) => {
    if (isNaN(value)) return null
    setFormState({
      ...formState,
      first_name: value,
    })
  }

  const { data: currentFlatData } = useGetFlatByIdQuery({
    id: Number(chatRefFlatData?.chatsDataFSM?.flat || editData?.target?.flat),
  })

  const getTenantsPhonesForCurrentFlat = () => {
    const currentFlatTenants = currentFlatData?.tenants || []

    if (!withAddressFields && formState?.flat_id) {
      return tenantsData?.filter((o: any) => currentFlatTenants.includes(o.id))
    }

    if (withAddressFields && formState?.flat_id) {
      return (
        tenantsData?.filter((o: any) => {
          return flatsByBuildingData.filter((flat: any) => flat.id == formState?.flat_id)[0]
            ?.tenants
            ? flatsByBuildingData
                .filter((flat: any) => flat.id == formState?.flat_id)[0]
                ?.tenants.includes(o.id)
            : o
        }) || []
      )
    } else {
      return (
        tenantsData?.filter((o: any) => {
          return chatRefFlatData?.tenants ? chatRefFlatData?.tenants.includes(o.id) : o
        }) || []
      )
    }
  }

  const [files, setFiles] = useState<any>([])
  const [flatsByBuildingForSelect, setFlatsByBuildingForSelect] = useState<any>([])

  React.useEffect(() => {
    const flatsToShow = flatsByBuildingData
      ?.filter(({ building }: any) => building?.id == formState?.building_id)
      ?.map(({ id, number: name }: any) => {
        return { id, name: 'flat_id', label: name }
      })

    setFlatsByBuildingForSelect(flatsToShow)
  }, [files])

  const filterOptions = createFilterOptions({
    matchFrom: 'start',
    stringify: (option: any) => option,
  })

  if (isAppealLoading || isBuildingLoading || isFlatsLoading) return <></>
  return (
    <div>
      <div onClick={handleOpen}>{btnTitle}</div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>
          <div className={styles.main_container}>
            <div>
              <div className={styles.info_container}>
                <Title size='24px' margin={'0 0 8px 0'}>
                  Заявка {editData?.id && `№${editData?.id}`}
                </Title>

                {withAddressFields && (
                  <div className={styles.address_grid}>
                    <FormControl fullWidth>
                      <InputLabel id='complex-label' size='small'>
                        ЖК
                      </InputLabel>

                      <Select
                        size='small'
                        name='complex'
                        labelId='complexes-label'
                        id='complexes-label'
                        value={formState?.complex}
                        label='ЖК'
                        placeholder='ЖК'
                        onChange={(e: any) => {
                          console.log('#LOG_88_COMP_NAM', e.target)
                          setFormState({
                            ...formState,
                            [e.target.name]: e.target.value,
                            building_id: null,
                            building_id_label: null,
                          })
                        }}
                      >
                        {complexesData?.map(({ id, name }: any) => (
                          <MenuItem value={id} key={id}>
                            {name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <FormControl fullWidth>
                      <Autocomplete
                        disabled={formState?.complex == null}
                        style={{ width: 'auto' }}
                        disablePortal
                        size='small'
                        id='combo-box-demo-uuu'
                        value={formState?.building_id_label || ''}
                        // filterOptions={filterOptions} // use them here
                        options={
                          buildingsData?.map(({ id, address: name }: any) => ({
                            name: 'building_id',
                            value: name,
                            valueId: id,
                            label: name,
                            id,
                            // id: name,
                          })) || []
                        }
                        // options={[
                        //   { label: 'Макарова 54', year: 1994 },
                        //   { label: '45 Макарова', year: 1972 },
                        //   { label: 'Акрв', year: 1974 },
                        //   { label: 'уацупцупц', year: 2008 },
                        //   { label: 'цмцукцуи', year: 1957 },

                        //   { label: 'Мактт', year: 1994 },
                        // ]}
                        onChange={(e: any, o: any) => {
                          console.log('Eee', o)
                          setFormState({
                            ...formState,
                            building_id: o?.valueId,
                            building_id_label: o?.label,
                            flat_id: null,
                            flat_id_label: null,
                          })
                        }}
                        // Filtering issue solution - renderOption key
                        // https://lockstep.io/blog/debugging-lesson-well-learned-fixing-material-ui-autocomplete-search/
                        renderOption={(params, option) => (
                          <li
                            key={option.id}
                            className={styles.completeSearchItem}
                            {...params}
                            // onClick={(options: any) => {
                            //   console.log('OPT', option)
                            //   setFormState({
                            //     ...formState,
                            //     building_id: options?.valueId,
                            //     building_id_label: options?.label,
                            //     flat_id: null,
                            //     flat_id_label: null,
                            //   })
                            // }}
                          >
                            {option.label}
                          </li>
                        )}
                        // options={[
                        //   { label: 'The Shawshank Redemption', year: 1994 },
                        //   { label: 'The Godfather', year: 1972 },
                        //   { label: 'The Godfather: Part II', year: 1974 },
                        //   { label: 'The Dark Knight', year: 2008 },
                        //   { label: '12 Angry Men', year: 1957 },
                        // ]}
                        sx={{ width: 300 }}
                        renderInput={(params) => (
                          <TextField value={'55'} {...params} label='Дома' />
                        )}
                      />
                    </FormControl>
                    <FormControl fullWidth>
                      <Autocomplete
                        style={{ width: 'auto' }}
                        disablePortal
                        size='small'
                        id='combo-box-demo'
                        value={formState?.flat_id_label || ''}
                        // options={flatsByBuildingForSelect}
                        options={
                          flatsByBuildingData
                            ?.filter(({ building }: any) => building?.id == formState?.building_id)
                            ?.map(({ id, number }: any) => {
                              return { flat_id: id, label: number }
                            }) || []
                        }
                        onChange={(e: any, o: any) => {
                          setFormState({
                            ...formState,
                            flat_id: o?.flat_id,
                            flat_id_label: o?.label,
                          })
                        }}
                        // options={[
                        //   { label: 'The Shawshank Redemption', year: 1994 },
                        //   { label: 'The Godfather', year: 1972 },
                        //   { label: 'The Godfather: Part II', year: 1974 },
                        //   { label: 'The Dark Knight', year: 2008 },
                        //   { label: '12 Angry Men', year: 1957 },
                        // ]}
                        sx={{ width: 300 }}
                        renderInput={(params) => <TextField {...params} label='Квартира' />}
                      />
                    </FormControl>
                  </div>
                )}
                {!withAddressFields && (
                  <Input
                    size='small'
                    disable={true}
                    error={errorList.includes('title') && Boolean(error)}
                    // value={chatRefFlatData?.currentFlatDataAddress}
                    value={formState.title}
                    name='title'
                    id='street-field'
                    label='Адрес'
                    placeholder='Адрес'
                    variant='outlined'
                    onChange={onChangeFormHandler}
                  />
                )}
                <Input
                  size='small'
                  // error={error == 'description' && Boolean(error)}
                  error={errorList.includes('description') && Boolean(error)}
                  value={formState.description}
                  disable={isDisabled}
                  name='description'
                  id='street-field'
                  label='Описание'
                  placeholder='Описание'
                  variant='outlined'
                  onChange={onChangeFormHandler}
                />

                <FileUploadOrder
                  files={files}
                  setFiles={setFiles}
                  getFileArr={(files: any) => setFiles(files)}
                  filesArrOuter={files}
                />
                <Title size='17px' margin={'8px 0 0 0'}>
                  Контактная информация
                </Title>
                <Select
                  size='small'
                  name='complex'
                  labelId='complexes-label'
                  id='complexes-label'
                  value={addTenantOption}
                  onChange={() =>
                    setAddTenantOption(
                      addTenantOption === AddTenantOption.ADD_NEW_USER
                        ? AddTenantOption.FROM_DB
                        : AddTenantOption.ADD_NEW_USER,
                    )
                  }
                >
                  {Object.values(AddTenantOption)?.map((item: any) => (
                    <MenuItem value={item} key={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
                {addTenantOption === AddTenantOption.FROM_DB && (
                  <>
                    <Autocomplete
                      disabled={withAddressFields && !formState?.flat_id}
                      size='small'
                      noOptionsText={<Box display='none' />}
                      id='country-select-demo'
                      onChange={onAutocompleteHandler}
                      onInputChange={onAutocompleteInputChange}
                      options={getTenantsPhonesForCurrentFlat() || []}
                      autoHighlight
                      getOptionLabel={(option: any) => {
                        console.log('#LOG_88___value', option)
                        return option.last_name + ' ' + option.first_name + ' ' + option.middle_name
                      }}
                      renderOption={(props, option) => (
                        <Box component='li' sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                          <div className={styles.option_item}>
                            <div className={styles.option_item_name}>
                              {`${option.last_name} ${option.first_name} ${option.middle_name}`}
                            </div>
                            <div className={styles.option_item_phone}>{option.phone}</div>
                          </div>
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          autoFocus={true}
                          label='Введите имя жильца'
                          value={'8998'}
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: 'new-password', // disable autocomplete and autofill
                          }}
                        />
                      )}
                    />

                    <Input
                      error={
                        errorList.includes('phone') ||
                        (errorList.includes('initiated_by') && Boolean(error))
                      }
                      size='small'
                      onChange={(e: any) => {
                        setFormState({ ...formState, [e.target.name]: e.target.value?.trim() })
                      }}
                      name='phone'
                      value={formState.phone?.trim()}
                      disable={true}
                      id='street-field-iddd-oo'
                      placeholder='Номер телефона'
                      variant='outlined'
                    />
                  </>
                )}

                {addTenantOption === AddTenantOption.ADD_NEW_USER && (
                  <>
                    <Input
                      size='small'
                      onChange={(e: any) => {
                        setFormState({ ...formState, [e.target.name]: e.target.value })
                      }}
                      name='client_fullname'
                      value={formState.client_fullname}
                      id='street-field-iddd'
                      label='Имя того, кто будет дома'
                      placeholder='Имя того, кто будет дома'
                      variant='outlined'
                    />
                    <Input
                      error={
                        errorList.includes('phone') ||
                        (errorList.includes('initiated_by') && Boolean(error))
                      }
                      size='small'
                      onChange={(e: any) => {
                        setFormState({ ...formState, [e.target.name]: e.target.value })
                      }}
                      name='phone'
                      value={formState.phone}
                      id='street-field-iddd'
                      label='Телефон для связи'
                      placeholder='Телефон для связи'
                      variant='outlined'
                    />
                  </>
                )}
              </div>
            </div>
            <div className={styles.options_container}>
              <div>
                <div className={styles.date_container}>
                  <span></span>
                  <Button
                    appearance='container'
                    onClick={() => {
                      fillFields()
                      handleClose()
                    }}
                  >
                    <CrossSvg />
                  </Button>
                </div>

                <div className={styles.order_options}>
                  <div className={styles.option_row}>
                    <span className={styles.labels}>Статус заявки</span>

                    <SelectGhost
                      onChangeHandler={(value: any) => {
                        setFormState({ ...formState, status: value })
                      }}
                      valueSelected={formState.status}
                      placeholder='Не выбрана'
                      options={optionsStatus}
                      defaultSelected={optionsStatus[0].value}
                    />
                  </div>
                  <div className={styles.option_row}>
                    <span className={styles.labels}>Срочность</span>
                    <SelectGhost
                      onChangeHandler={(value: any) => {
                        setFormState({ ...formState, priority: value })
                      }}
                      valueSelected={formState.priority}
                      placeholder='Не выбрана'
                      options={optionsUrgency}
                      defaultSelected={optionsUrgency[0].value}
                    />
                  </div>
                  <div className={styles.option_row}>
                    <span className={styles.labels}>Тип</span>
                    <SelectGhost
                      onChangeHandler={(value: any) => {
                        setFormState({ ...formState, typeVal: value })
                      }}
                      valueSelected={formState.typeVal}
                      placeholder='Не выбрана'
                      options={Object.entries(TypeName).map((typeVal) => ({
                        id: typeVal[0],
                        value: typeVal[0],
                        title: typeVal[1],
                      }))}
                      // defaultSelected={
                      //   Object.entries(TypeName).map((typeVal) => ({
                      //     id: typeVal[0],
                      //     value: typeVal[0],
                      //     title: typeVal[1],
                      //   }))[0].value
                      // }
                    />
                  </div>

                  <div className={styles.option_row}>
                    <span className={styles.labels}>Тема</span>
                    <SelectGhost
                      onChangeHandler={(value: any) => {
                        console.log('PRIOR_id: ', value)
                        setFormState({
                          ...formState,
                          appealIssueType: value,
                          appealIssueType_id: appealIssueType?.filter(
                            ({ title }: any) => title === value,
                          )[0]?.id,
                        })
                      }}
                      valueSelected={formState.appealIssueType}
                      placeholder='Не выбрана'
                      options={
                        appealIssueType?.map(({ id, title }: any) => ({
                          id,
                          value: title,
                          title,
                        })) || []
                      }
                      // defaultSelected={
                      //   appealIssueType?.map(({ id, title }: any) => ({
                      //     id,
                      //     value: title,
                      //     title,
                      //   }))[0].value
                      // }
                    />
                  </div>

                  <div className={styles.option_row}>
                    <span className={styles.labels}>Дедлайн</span>

                    <Input
                      size='small'
                      type='date'
                      name='deadline'
                      error={errorList.includes('deadline') && Boolean(error)}
                      value={formState.deadline}
                      id='street-field'
                      variant='outlined'
                      onChange={onChangeFormHandler}
                    />
                  </div>
                  <div className={styles.option_row}>
                    <span className={styles.labels}>Дата закрытия</span>
                    <span className={styles.labels}>
                      {formState.close_date ? formState.close_date : '-'}
                    </span>
                  </div>
                  {/* <Calendar label='Дедлайн' /> */}
                </div>
              </div>

              {editData && Object.keys(editData).length > 0 ? (
                <Button
                  appearance='outlined'
                  onClick={handleEditAppeal}
                  className={styles.btn_close}
                  disabled={
                    formState.title == '' ||
                    formState.description?.trim() == '' ||
                    formState.priority == '' ||
                    formState.complex == '' ||
                    formState.phone?.trim() == '' ||
                    formState.client_fullname == '' ||
                    formState.appealIssueType_id == '' ||
                    formState.appealIssueType == '' ||
                    formState.typeVal == '' ||
                    formState.deadline == '' ||
                    !formState.description
                  }
                >
                  {isLoadingEdit ? 'Сохранение...' : 'Сохранить изменения'}
                </Button>
              ) : (
                <Button
                  appearance='outlined'
                  onClick={handleCreateAppeal}
                  className={styles.btn_close}
                  disabled={
                    formState.title == '' ||
                    formState.description?.trim() == '' ||
                    formState.priority == '' ||
                    formState.complex == '' ||
                    formState.phone?.trim() == '' ||
                    formState.client_fullname == '' ||
                    formState.appealIssueType_id == '' ||
                    formState.appealIssueType == '' ||
                    formState.typeVal == '' ||
                    formState.deadline == '' ||
                    !formState.description
                  }
                >
                  Создать заявку
                </Button>
              )}
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  )
}
