import React, { useEffect, useState } from 'react'
import { Button, Input, Title, FileUploadOrder, FileUpload } from 'components'
import styles from './SendFileClip.module.css'
import Modal from '@mui/material/Modal'
import { Autocomplete, Box, FormControl, InputLabel, TextField } from '@mui/material'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import { SelectGhost } from 'components/Input/SelectGhost/SelectGhost'
import { ReactComponent as CrossSvg } from 'assets/img/cross.svg'
import { ColorDot } from 'components/ColorDot/ColorDot.styles'
import {
  useCreateAppealMutation,
  useEditAppealMutation,
} from 'redux/features/appeals/appealsApiSlice'
import { useSelector } from 'react-redux'
import { useGetComplexesQuery } from 'redux/features/complexes/complexesApiSlice'
import {
  useGetBuildingsQuery,
  useLazyGetBuildingsQuery,
} from 'redux/features/buildings/buildingsApiSlice'
import { useGetTenantsQuery } from 'redux/features/tenats/tenantsApiSlice'
import { useGetFlatsBybuildingQuery } from 'redux/features/flats/flatApiSlice'
import {
  AddTenantOption,
  Priority,
  PriorityColor,
  PriorityName,
  Status,
  StatusColor,
  StatusName,
} from './constants'
import { IProps, Option } from './types'
import { useAddAttachmentMutation } from 'redux/features/attachments/attachmentsApiSlice'
import { ClipUploadSingleFile } from 'components/FileUpload/ClipUploadSingleFile'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 687,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  padding: '0',
  borderRadius: '8px',
  border: 'none',
}

const defaultFormState = {
  // complex: null,
  // status: null,
  // priority: null,
  // title: '',
  building_id: '',
  flat_id: '',
  address: '',
  description: '',
  client_fullname: '',
  client_phone_number: '',
  phone: '',
}
const StatusOption = ({ status }: any) => {
  return (
    <div className={styles.option_status}>
      <ColorDot style={{ backgroundColor: StatusColor[status] }} />
      <span>{StatusName[status]}</span>
    </div>
  )
}

const PriorityOption = ({ priority }: any) => {
  useEffect(() => {
    console.log('Object.keys(StatusName): + ', Object.keys(StatusName))
  }, [])
  return (
    <div className={styles.option_status}>
      <ColorDot style={{ backgroundColor: PriorityColor[priority] }} />
      <span>{PriorityName[priority]}</span>
    </div>
  )
}

const optionsStatus: Option[] = Object.keys(StatusName).map((status) => ({
  id: status,
  value: status,
  title: <StatusOption status={status} key={status} />,
}))

const optionsUrgency: Option[] = Object.keys(PriorityName).map((priority) => ({
  id: priority,
  value: priority,
  title: <PriorityOption priority={priority} key={priority} />,
}))

export const SendFileClip = ({
  btnTitle,
  editData,
  isDisabled = false,
  refetchAppeals,
  withAddressFields = false,
  sendMessageEvent,
}: IProps) => {
  const [formState, setFormState] = React.useState<any>({
    test: 'Hey',
    complex: null,
    building_id: '',
    flat_id: '',
    address: '',
    description: '',
    client_fullname: '',
    client_phone_number: '',
    status: null,
    priority: null,
    title: '',
  })

  const [createAppeal] = useCreateAppealMutation()
  const [editAppeal] = useEditAppealMutation()
  const [open, setOpen] = React.useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const chatRefFlatData = useSelector((state: any) => state?.chat?.chatRefFlatData)

  // const { data: buildingsData, refetch: refetchBuildings } = useGetBuildingsQuery({
  //   qury: '',
  //   residential_complex: formState?.complex,
  // })

  const [getBuildingsDataQuery, { data: buildingsData, isLoading: isBuildingLoading }] =
    useLazyGetBuildingsQuery()

  const { data: flatsByBuildingData, refetch: refetchBuilding } = useGetFlatsBybuildingQuery({
    building_id: formState.building_id,
    query: '',
  })

  const [error, setError] = useState('')
  const [attachmentUpload, { isLoading, isSuccess, isError, error: errorFileUpload }] =
    useAddAttachmentMutation()
  const [filesArr, setFilesArr] = useState<any>([])
  const [errorList, setErrorList] = useState<any>([])
  const { data: tenantsData } = useGetTenantsQuery('')
  const [addTenantOption, setAddTenantOption] = useState(AddTenantOption.FROM_DB)

  useEffect(() => {
    console.log('_LOG_files(()(', filesArr)
  }, [filesArr])

  // не взял isLoading из мутации так как нужно больше времени на refetchAppeals и loader исчезает
  const [isLoadingEdit, setIsLoadingEdit] = useState(false)

  const { data: complexesData } = useGetComplexesQuery({})

  useEffect(() => {
    if (editData) {
      setAddTenantOption(AddTenantOption.ADD_NEW_USER)
      setFormState({
        ...formState,
        title: editData?.target?.address,
        // chatRefFlatData?.currentFlatDataAddress
        // title: editData?.title,
        description: editData?.description,
        status: editData?.status,
        priority: editData?.priority,
        type: editData.type,
        issue: editData.issue,
        phone: editData.initiated_by.phone,
        client_fullname: editData.initiated_by.fullname,
        tenant: editData.initiated_by.tenant,
        residential_complex: editData.residential_complex,
        building: editData.building,
        flat: editData.flat,
      })
    } else {
      setFormState({
        ...formState,
        title: `Здание ${chatRefFlatData?.building?.building} кв ${chatRefFlatData?.number}`,
        building: chatRefFlatData?.building?.id,
        complex: chatRefFlatData?.building?.residential_complex?.id,
      })
    }
  }, [editData, complexesData, chatRefFlatData])

  useEffect(() => {
    formState.complex &&
      getBuildingsDataQuery({ qury: '', residential_complex: formState?.complex })
    // refetchBuildings()
  }, [formState.complex])

  const [fileTest, setFileTest] = useState<any>()

  useEffect(() => {
    console.log('fileTest', fileTest)
    const fd = new FormData()
    fd.append('attachment', fileTest)
    console.log('fileTest', fd)
  }, [fileTest])

  const [file, setFile] = useState(null)

  const handleFileChange = (event: any) => {
    // Set the first selected file
    setFile(event.target.files[0])
  }

  const handleSubmit = (event: any) => {
    event.preventDefault()
    if (!filesArr[0]) {
      alert('Please select a file first!')
      return
    }

    // FormData is used to handle file uploads in POST requests
    // const formData = new FormData()
    // formData.append('attachment', file)

    // Call the mutation function
    attachmentUpload({ attachmentFile: filesArr[0] }).then((res: any) => {
      console.log('fileTest_8880', res)
      sendMessageEvent(null, [res?.data?.id])
      handleClose()
    })
    // attachmentUpload({ attachmentFile: file })
    // attachmentUpload({ attachmentFile: formData })
  }

  return (
    <div>
      <div onClick={handleOpen}>{btnTitle}</div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>
          <div className={styles.main_container_}>
            <div>
              <div className={styles.info_container}>
                <Title size='24px' margin={'0 0 8px 0'}>
                  Загрузите файл
                </Title>
                {/* 
                <form onSubmit={handleSubmit}>
                  <input type='file' onChange={handleFileChange} />
                  <button type='submit' disabled={isLoading}>
                    Upload File
                  </button>
                  {isError && <p>Error: {errorFileUpload?.toString()}</p>}
                  {isSuccess && <p>File uploaded successfully!</p>}
                </form> */}

                <ClipUploadSingleFile
                  getFileArr={(files: any) => setFilesArr(files)}
                  handleSubmit={handleSubmit}
                />

                {/* <Button
                  appearance='outlined'
                  onClick={handleCreateAppeal}
                  className={styles.btn_close}
                  disabled={
                    formState.title == '' ||
                    formState.description?.trim() == '' ||
                    formState.priority == '' ||
                    formState.complex == '' ||
                    formState.phone?.trim() == '' ||
                    formState.client_fullname == ''
                  }
                >
                  Создать заявку
                </Button>
             */}
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  )
}
